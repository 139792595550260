import http from "./http";

export default class ReportService {

  // Get filtered data from the api lambda, parsing the input arguments as url query string
  static getReportListing = async (route: string): Promise<any> => {
    return http.get<any>(`prod/sparkdata?route=${route}`).then(
      (res) => res.data
    );
  };  

}