import React from "react";
 import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
 import moment from "moment";
 import { Card, Box, Button } from '@mui/material';
 import { Divider } from '@mui/material';
 import AxisLabel from '../yaxisLabel'
 import formatXAxis from "../xaxisFormat";
 import { Typography } from "@material-ui/core";
 
 
 export interface priceChartProperties {
     inputDataArray: any;
     choice: any;
     RTLastUpdatedValue: any;
     status:any;
 }
 
 const DARTChart: React.FunctionComponent<priceChartProperties> = ({ inputDataArray, choice, RTLastUpdatedValue, status }) => {
 
 
   const CustomTooltip = ({ active, payload, label }: any) => {
     if (active && payload && payload[0] && payload.length) {
       return (
         <Card elevation={3} className="custom-tooltip" sx={{ width: "210px" }}>
           <p className="label">&ensp;{`Date: ${moment(label).format('MM/DD - HH:mm:ss')}`}</p>
           {payload[0] && <p className="desc">&ensp;{`${payload[0].name}: $${payload[0].value.toFixed(2)}`}</p>}
           {payload[1] && <p className="desc">&ensp;{`${payload[1].name}: $${payload[1].value.toFixed(2)}`}</p>}
           {(choice === "Combined") && payload[2] && <p className="desc">&ensp;{`${payload[2].name}: $${payload[2].value.toFixed(2)}`}</p>}
           {(choice === "Combined") && payload[3] && <p className="desc">&ensp;{`${payload[3].name}: $${payload[3].value.toFixed(2)}`}</p>}
 
         </Card>
       );
     }
 
     return null;
   };
   const renderLegend = () => {
 
     return (
       <ul>
         <Button disableRipple sx={{ '&:hover': { backgroundColor: '#55622b', borderColor: '#55622b', boxShadow: 'none' }, backgroundColor: "#55622b", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>DART Price Actual</Button>
         <Button disableRipple sx={{ '&:hover': { backgroundColor: '#799b3e', borderColor: '#799b3e', boxShadow: 'none' }, backgroundColor: "#799b3e", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>DART Price Forecast</Button>
       </ul>
     );
   }
 
   return (
     <div>
       <div style={{ display: "inline" }}>
         <Box sx={{ typography: 'body1', display: "flex", marginTop: "-40px" }}>
           <Box sx={{fontFamily: "calibri", paddingBottom: "8px" }}>
             <Typography style={{ fontWeight: "bold", fontSize: "1.5rem", color: status !== "SUCCESS" ? "red" : "black" }}>NORTH HUB DART PRICE {RTLastUpdatedValue}
             </Typography>
           </Box>
         </Box>
       </div>
 
       <Divider color="black" sx={{ borderBottomWidth: 4, padding: "2.5px", marginBottom: "40px", border: "30px"}} />
       <ResponsiveContainer width="100%" height={450} >
         <LineChart
           width={500}
           height={400}
           data={inputDataArray}
           margin={{
             top: 5,
             right: 30,
             left: 20,
             bottom: 5,
           }}
 
         >
           <CartesianGrid strokeDasharray="3 3" />
           <XAxis xAxisId="0" dataKey="DateTime" tickFormatter={formatXAxis} />
           <YAxis label={<AxisLabel axisType="yAxis" x={25} y={175} width={0} height={0} stroke="3">Price ($/MWh)</AxisLabel>} />
           <Tooltip content={<CustomTooltip />} wrapperStyle={{ maxHeight: 300, width: 200, backgroundColor: '#FFFFFF' }} />
           <Legend content={renderLegend} />
           {choice === "RT" && <Line type="monotone" name="DART Price Actual" dataKey="historical_dart_target_value" stroke="#55622b" strokeWidth={3} dot={false} />}
           {choice === "RT" && <Line type="monotone" name="DART Price Forecast" dataKey="forecast_dart_target_value" stroke="#799b3e" strokeWidth={2} dot={false} />}
           {choice === "Historical" && <Line type="monotone" name="DART Price Actual" dataKey="dart_target_value_actual" stroke="#55622b" strokeWidth={3} dot={false} />}
           {choice === "Historical" && <Line type="monotone" name="DART Price Forecast" dataKey="dart_target_value" stroke="#799b3e" strokeDasharray="4 4 2" strokeWidth={2} dot={false} />}
           {choice === "Combined" && <Line type="monotone" name="DART Price Forecast" dataKey="historical_dart_target_value" stroke="#799b3e" strokeDasharray="4 4 2" strokeWidth={2} dot={false} />}
           {choice === "Combined" && <Line type="monotone" name="DART Price Actual" dataKey="dart_target_value_actual" stroke="#55622b" strokeWidth={3} dot={false} />}
           {choice === "Combined" && <Line type="monotone" name="DART Price Forecast" dataKey="forecast_dart_target_value" stroke="#799b3e" strokeWidth={2} dot={false} />}
 
         </ LineChart>
       </ResponsiveContainer>
     </div>
   );
 };
 
	 export default DARTChart;