import React from "react";
/**
 * 
 * @param param0 default params from recharts
 * @returns Y-axis rotated by 90 degrees
 */
const AxisLabel = ({ axisType, x=0, y=0, width, height, stroke, children }:any) => {
    const isVert = axisType === 'yAxis';
    const cx = isVert ? x : x + (width / 2);
    const cy = isVert ? (height / 2) + y : y + height + 10;
    const rot = isVert ? `270 ${cx} ${cy}` : 0;
    return (
      <text x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle" stroke={stroke}>
        {children}
      </text>
    );
  };
export default AxisLabel