import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from "moment";
import { Button, Divider, Card } from '@mui/material';
import AxisLabel from '../yaxisLabel'
import formatXAxis from "../xaxisFormat";
import { Typography } from "@material-ui/core";

export interface outageChartProperties {
  inputDataArray: any;
  choice: any;
  status:any;
}


const renderLegend = () => {
  return (
    <ul>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#000000', borderColor: '#000000', boxShadow: 'none' }, backgroundColor: "#000000", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>Coal Outage</Button>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#ff7f00', borderColor: '#ff7f00', boxShadow: 'none' }, backgroundColor: "#ff7f00", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>Gas Outage</Button>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#ab3015', borderColor: '#ab3015', boxShadow: 'none' }, backgroundColor: "#ab3015", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>Dual Fuel Outage</Button>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#1799d1', borderColor: '#1799d1', boxShadow: 'none' }, backgroundColor: "#1799d1", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>Hydro Outage</Button>
      {/* <Button disableRipple sx={{ '&:hover': { backgroundColor: '#52fc03', borderColor: '#52fc03', boxShadow: 'none' }, backgroundColor: "#52fc03", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>Wind Outage</Button> */}
      {/* <Button disableRipple sx={{ '&:hover': { backgroundColor: '#fc6203', borderColor: '#fc6203', boxShadow: 'none' }, backgroundColor: "#fc6203", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>Solar Outage</Button> */}
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#bf7f0f', borderColor: '#bf7f0f', boxShadow: 'none' }, backgroundColor: "#bf7f0f", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>Energy Storage Outage</Button>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#d12717', borderColor: '#d12717', boxShadow: 'none' }, backgroundColor: "#d12717", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>Other Fuels Outage</Button>
    
    </ul>
  );
}

const OutageChart: React.FunctionComponent<outageChartProperties> = ({ inputDataArray, choice, status }) => {
  
  const CustomTooltip = ({ active, payload, label }: any) => {

    if (active && payload && payload[0] && payload.length) {
      return (
        <Card elevation={3} className="custom-tooltip" sx={{ width: "210px" }}>
          <p className="label">&ensp;{`Date: ${moment(label).format('MM/DD - HH:mm:ss')}`}</p>
          {/* {payload[0] && <p className="desc">&ensp;{`${payload[0].name}: ${payload[0].value.toFixed(0)}`}</p>}
          {payload[1] && <p className="desc">&ensp;{`${payload[1].name}: ${payload[1].value.toFixed(0)}`}</p>}
          {(choice === "Combined") && payload[2] && <p className="desc">&ensp;{`${payload[2].name}: ${payload[2].value.toFixed(0)}`}</p>}
          {(choice === "Combined") && payload[3] && <p className="desc">&ensp;{`${payload[3].name}: ${payload[3].value.toFixed(0)}`}</p>} */}
          {payload[0] && <p className="desc">&ensp;{`${payload[0].name}: ${payload[0].value.toFixed(0)}`}</p>}
          {payload[1] && <p className="desc">&ensp;{`${payload[1].name}: ${payload[1].value.toFixed(0)}`}</p>}
          {payload[2] && <p className="desc">&ensp;{`${payload[2].name}: ${payload[2].value.toFixed(0)}`}</p>}
          {payload[3] && <p className="desc">&ensp;{`${payload[3].name}: ${payload[3].value.toFixed(0)}`}</p>}
          {payload[4] && <p className="desc">&ensp;{`${payload[4].name}: ${payload[4].value.toFixed(0)}`}</p>}
          {payload[5] && <p className="desc">&ensp;{`${payload[5].name}: ${payload[5].value.toFixed(0)}`}</p>}
          {payload[6] && <p className="desc">&ensp;{`${payload[6].name}: ${payload[6].value.toFixed(0)}`}</p>}
          
        </Card>
      );
    }

    return null;
  };

  return (
    
    
    <div>
      <Typography style={{ fontWeight: "bold", fontSize: "1.5rem", color:status!=="SUCCESS"?"red":"black"}}>OUTAGES</Typography>
      <Divider color="black" sx={{ borderBottomWidth: 4, padding: "2.5px", marginBottom: "40px", border: "30px" }} />
      <ResponsiveContainer width="100%" height={450}>
        <BarChart 
          width={500}
          height={400}
          data={inputDataArray}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis xAxisId="0" dataKey="DateTime"
            tickFormatter={formatXAxis}
          />

          <YAxis label={<AxisLabel axisType="yAxis" x={25} y={175} width={0} height={0} stroke="3">Outage (MW)</AxisLabel>} />
          <Tooltip content={<CustomTooltip />} wrapperStyle={{ maxHeight: 300, width: 200, backgroundColor: '#FFFFFF' }} />
          <Legend content={renderLegend} />
          <Bar dataKey="coal_outage" name="Coal Outage" stackId="a" fill="#000000" />
          <Bar dataKey="gas_outage" name="Gas Outage" stackId="a" fill="#ff7f00" />
          <Bar dataKey="dual_fuel_outage" name="Dual Fuel Outage" stackId="a" fill="#ab3015" />
          <Bar dataKey="hydro_outage" name="Hydro Outage" stackId="a" fill="#1799d1" />
          {/* <Bar dataKey="wind_outage" name="Wind Outage" stackId="a" fill="#52fc03" /> */}
          {/* <Bar dataKey="solar_outage" name="Solar Outage" stackId="a" fill="#fc6203" /> */}
          <Bar dataKey="energy_storage_outage" name="Energy Storage Outage" stackId="a" fill="#bf7f0f" />
          <Bar dataKey="other_fuels_outage" name="Other Fuels Outage" stackId="a" fill="#d12717" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OutageChart;