import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import MapTwoToneIcon from '@mui/icons-material/MapTwoTone';
import Button from '@mui/material/Button';
import { Box, Grid } from '@material-ui/core';
import ercotmap from "../../../static/ercot-map.jpg";

export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick} style={{backgroundColor:"black"}}>
        <Grid container item xs={6} direction="column" spacing={0} style={{paddingLeft:"-5"}}>
          <Box style={{marginLeft:'0rem'}}>
            <MapTwoToneIcon style={{ fontSize: "2.5rem" }}/>
          </Box>
          <Box>
            <Typography style={{fontSize:"17px", marginLeft:"0.1rem"}}>
              Location  
            </Typography>
          </Box>  
        </Grid>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{marginLeft:"3rem"}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <img src={ercotmap} alt="ERCOT Map"/>
      </Popover>
    </div>
  );
}
