import http from "./http";

export default class DashboardService {

  // Get filtered data from the api lambda, parsing the input arguments as url query string
  static getFilteredData = async (market:string, rtmodelname:string, damodelname:string, history_choice: string, prediction_choice: string): Promise<any> => {
    return http.get<any>(`prod/sparkdata?market=${market}&rtmodelname=${rtmodelname}&damodelname=${damodelname}&prediction_choice=${prediction_choice}&history_choice=${history_choice}`).then(
      (res) => res.data
    );
  }; 

}
