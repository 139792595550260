import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from "moment";
import { Card, Box, Button, Link } from '@mui/material';
import { Divider } from '@mui/material';
import AxisLabel from '../yaxisLabel'
import formatXAxis from "../xaxisFormat";
import {FileDownloadUtilities} from "../../../../services/FileDownloadService"
import { Typography } from "@material-ui/core";

export interface priceChartProperties {
  inputDataArray: any;
  choice: any;
  RTLastUpdatedValue: any;
  status:any;
}

const RTPriceChart: React.FunctionComponent<priceChartProperties> = ({ inputDataArray, choice, RTLastUpdatedValue, status }) => {


  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {

      return (
        <Card elevation={3}  className="custom-tooltip" sx={{width:"210px"}}>
          <p className="label">&ensp;{`Date: ${moment(label).format('MM/DD - HH:mm:ss')}`}</p>
          {payload[0] && <p className="desc">&ensp;{`${payload[0].name}: $${payload[0].value.toFixed(2)}`}</p>}
          {payload[1] && <p className="desc">&ensp;{`${payload[1].name}: $${payload[1].value.toFixed(2)}`}</p>}
          {(choice==="Combined")&&payload[2] && <p className="desc">&ensp;{`${payload[2].name}: $${payload[2].value.toFixed(2)}`}</p>}
          {(choice==="Combined")&&payload[3] && <p className="desc">&ensp;{`${payload[3].name}: $${payload[3].value.toFixed(2)}`}</p>}
        
        </Card>
      );
    }
    return null;
  };
  const renderLegend = () => {

    return (
      <ul>
        <Button disableRipple sx={{ '&:hover': { backgroundColor: '#0e406a', borderColor: '#0e406a', boxShadow: 'none' }, backgroundColor: "#0e406a", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>RT Price Actual</Button>
        <Button disableRipple sx={{ '&:hover': { backgroundColor: '#237cc1', borderColor: '#237cc1', boxShadow: 'none' }, backgroundColor: "#237cc1", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>RT Price Forecast</Button>
      </ul>
    );
  }

  return (
    <div>
      <div style={{ display: "inline" }}>
        <Box sx={{ typography: 'body1', display: "flex", marginTop: "-40px" }}>
          <Box sx={{fontFamily: "calibri", paddingBottom: "8px" }}>
            <Typography style={{ fontWeight: "bold", fontSize: "1.5rem", color:status!=="SUCCESS"?"red":"black"}}>NORTH HUB RT PRICE {RTLastUpdatedValue}</Typography>
          </Box>
        </Box>
        <Box sx={{ typography: 'body1', display: "flex", marginTop: "-18px", marginBottom:"-6px" }}>
          <Box sx={{ fontWeight: "bold", fontSize: "1.2rem", fontFamily: "calibri", paddingBottom: "8px", paddingRight:"10rem"}}>
            <Link component="button" onClick={()=>FileDownloadUtilities.initiateCsvFileDownload('prod/sparkdata?market=ercot&markettype=rt&modelname=north_hub&datatype=results&filetype=csv', 'North_Hub_RT_Prediction.csv')}>Download Prediction CSV File</Link>
          </Box>
          <Box sx={{ fontWeight: "bold", fontSize: "1.2rem", fontFamily: "calibri", paddingBottom: "8px" }}>
            <Link component="button" onClick={()=>FileDownloadUtilities.initiateCsvFileDownload('prod/sparkdata?market=ercot&markettype=rt&modelname=north_hub&datatype=history&filetype=csv', 'North_Hub_RT_History.csv')}>Download History CSV File</Link>
          </Box>
        </Box>
      </div>

      <Divider color="black" sx={{ borderBottomWidth: 4, padding: "2.5px", paddingTop: "", marginBottom: "40px", border: "30px" }} />
      <ResponsiveContainer width="100%" height={450} >
        <LineChart
          width={500}
          height={400}
          data={inputDataArray}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}

        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis xAxisId="0" dataKey="DateTime" tickFormatter={formatXAxis} />
          <YAxis label={<AxisLabel axisType="yAxis" x={25} y={175} width={0} height={0} stroke="3">Price ($/MWh)</AxisLabel>} />
          <Tooltip content={<CustomTooltip choice={choice}/>} wrapperStyle={{ maxHeight: 300, width: 200, backgroundColor: '#FFFFFF' }} />
          <Legend content={renderLegend} />
          {choice === "RT" && <Line type="monotone" name="RT Price Actual" dataKey="historical_target_value" stroke="#0e406a" strokeWidth={3} dot={false} />}
          {choice === "RT" && <Line type="monotone" name="RT Price Forecast" dataKey="forecast_target_value" stroke="#237cc1" strokeWidth={2} dot={false} />}
          {choice === "Historical" && <Line type="monotone" name="RT Price Actual" dataKey="target_value_actual" stroke="#0e406a" strokeWidth={3} dot={false} />}
          {choice === "Historical" && <Line type="monotone" name="RT Price Forecast" dataKey="target_value" strokeDasharray="4 1 2" stroke="#237cc1" strokeWidth={2} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="RT Price Forecast" dataKey="historical_target_value" strokeDasharray="4 1 2" stroke="#237cc1" strokeWidth={2} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="RT Price Actual" dataKey="target_value_actual" stroke="#0e406a" strokeWidth={3} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="RT Price Forecast" dataKey="forecast_target_value" stroke="#237cc1" strokeWidth={2} dot={false} />}
        </ LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RTPriceChart;