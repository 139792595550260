/* eslint-disable @typescript-eslint/no-unused-vars */
import { createTheme } from "@material-ui/core/styles";

/**
 * Theme settings for the applications.
 * This project makes use of Material-ui's ThemeProvider which then takes this file and apply it across the application.
 * For more information please see:
 *  - https://material-ui.com/customization/default-theme/  // file structure
 *  - https://material-ui.com/customization/theming/        // Material-ui theme customization docs
 */

const tcFontFamily = ["Calibri", "Helvetica", "Arial", "sans-serif"].join(",");
const buttonFontFamily = ["Roboto", "Helvetica", "Arial", "sans-serif"].join(",");

/**
 * Colors marked with a "generated" comment were generated using the material.io color
 *  tool, https://material.io/resources/color/, to fill in the blanks to maintain a consistent
 *  look throughout the material ui components.
 */
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      dark: "#005090", // generated
      main: "#237CC1",
      light: "#64ABF4", // generated
    },
    primaryVariant: {
      dark: "#4A6D0D", // generated
      main: "#799B3E",
      light: "#AACC6C", // generated
    },
    primaryTints: {
      100: "#E9F1F8",
      200: "#D3E4F2",
      300: "#BDD7EC",
      400: "#A7CAE6",
      500: "#91BDE0",
      600: "#7BB0D9",
      700: "#65A3D3",
      800: "#4F96CD",
      900: "#3989C7",
    },
    primaryVariantTints: {
      100: "#F1F5EB",
      200: "#E4EBD8",
      300: "#D6E1C5",
      400: "#C9D7B1",
      500: "#BCCD9E",
      600: "#AEC38B",
      700: "#A1B977",
      800: "#93AF64",
      900: "#86A551",
    },
    secondary: {
      dark: "#0E406A",
      main: "#5AA3D2",
      light: "#96D1F2",
    },
    success: {
      dark: "#4A6D0D", // generated
      main: "#799B3E",
      light: "#AACC6C", // generated
    },
    error: {
      dark: "#7B0006", // generated
      main: "#B2282E",
      light: "#EA5C57", // generated
    },
    warning: {
      dark: "#BE6400", // generated
      main: "#F7921E",
      light: "#FFC353", // generated
    },
    neutral: {
      dark: "#2c2e30", // generated
      main: "#555759",
      light: "#818486", // generated
    },
    grey: {
      50: "#FFFFFF",
      100: "#EEEEEE",
      200: "#DDDDDD",
      300: "#CCCCCD",
      400: "#BBBBBC",
      500: "#AAABAC",
      600: "#999A9B",
      700: "#88898A",
      800: "#77787A",
      900: "#666769",
    },
    text: {
      primary: "#555759",
      secondary: "#555759",
    },
    background: {
      paper: "#FFFFFF",
      default: "#F4F4F4",
    },
  },
  typography: {
    fontFamily: tcFontFamily,
    h1: {
      fontFamily: tcFontFamily,
      fontWeight: 700,
      fontSize: "3.25rem",
      textTransform: "none",
    },
    h2: {
      fontFamily: tcFontFamily,
      fontWeight: 700,
      fontSize: "2.75rem",
      textTransform: "none",
    },
    h3: {
      fontFamily: tcFontFamily,
      fontWeight: 700,
      fontSize: "2.25rem",
      textTransform: "none",
    },
    h4: {
      fontFamily: tcFontFamily,
      fontWeight: 700,
      fontSize: "1.5rem",
      textTransform: "none",
    },
    h5: {
      fontFamily: tcFontFamily,
      fontWeight: 700,
      fontSize: "1.25rem",
      textTransform: "none",
    },
    h6: {
      fontFamily: tcFontFamily,
      fontWeight: 700,
      fontSize: "1.125rem",
      textTransform: "none",
    },
    body1: {
      fontFamily: tcFontFamily,
      fontWeight: 400,
      fontSize: "1rem",
      textTransform: "none",
    },
    body2: {
      fontFamily: tcFontFamily,
      fontWeight: 400,
      fontSize: "0.875rem",
      textTransform: "none",
    },
    button: {
      fontFamily: buttonFontFamily,
      fontWeight: 600,
      fontSize: "0.875rem",
      textTransform: "uppercase",
    },
  },
});

/**
 * Any custom/variables will need to use module augmentation for the theme
 *  to accept them, https://material-ui.com/customization/palette/#adding-new-colors.
 */
declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    neutral: Palette["primary"];
    primaryVariant: Palette["primary"];
    primaryTints: Palette["grey"];
    primaryVariantTints: Palette["grey"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
    primaryVariant: PaletteOptions["primary"];
    primaryTints: PaletteOptions["grey"];
    primaryVariantTints: PaletteOptions["grey"];
  }
}

export default theme;
