import React from "react";
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Card, CardHeader, CardContent, Box, Button, FormControl, Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

export interface FilterProperties {
    props: any;
}


const DataFilter: React.FunctionComponent<any> = (props) => {
    const [showForm, setShowForm] = React.useState(false);

    const [state, setState] = React.useState<String | null>(props.userCurrentChoice.state);
    const [historicalState, setHistoricalState] = React.useState<String | null>((props.userCurrentChoice.state.includes('&')===true )? props.userCurrentChoice.state.split('&')[1]: "");
    const [predState, setPredState] = React.useState<String | null>((props.userCurrentChoice.state.includes('&')===true) ? props.userCurrentChoice.state.split('&')[0] : "");
    const marketType = props.maketType;

    const handlePredChange = (event: any) => {
        if (predState === event.target.value) {

            setPredState("")
        }
        else {

            setPredState(event.target.value)
        }

    }

    const handleHistoricalChange = (event: any) => {
        // console.log("event triggered: ", event.target.value)
        if (historicalState === event.target.value) {
            setHistoricalState("")
        }
        else {
            setHistoricalState(event.target.value)
        }
    }

    const handleSubmit = (open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        let state = null //placeholder
        if (predState === "" && historicalState === "") {
            state = "24hours&1week_historical" //default
            setState(state) 
        }
        else if (predState === "") {
            state = historicalState
            setState(state) 

        }
        else if (historicalState === "") {
            state = predState
            setState(state) //default is 24_hours. 

        }
        else {
            state = predState + '&' + historicalState
            setState(state)
            setPredState(state.split('&')[0])
            setHistoricalState(state.split('&')[1])
        }

        props.setUserFilterChoice({ state }); //pass the change to its parent (Dashboard component)
        setShowForm(open);
    };

    const toggleDrawer = (open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(state) //default is 24_hours. 
        setShowForm(open);
    };

    const content = () => (
        <Box
            sx={{ width: 650 }}
            role="presentation"
            onKeyDown={toggleDrawer(false)}
        >
            <RadioGroup
                value={predState}
            >
                <FormControl>
                    <Card elevation={0} sx={{ maxWidth: 650 }}>
                        <CardHeader
                            title="Prediction Window"
                        />
                        <Divider color="black" sx={{ borderBottomWidth: 3, color: "black" }} />
                        <CardContent>

                            <Grid container item xs={6} direction="column" >
                                <FormControlLabel value="24hours" label="24 Hours"
                                    control={<Radio onClick={handlePredChange} icon={<CircleOutlinedIcon sx={{ color: "black" }} />} checkedIcon={<CheckCircleIcon sx={{ color: "black" }} />} />} />
                                <FormControlLabel value="48hours" label="48 Hours"
                                    control={<Radio onClick={handlePredChange} icon={<CircleOutlinedIcon sx={{ color: "black" }} />} checkedIcon={<CheckCircleIcon sx={{ color: "black" }} />} />} />

                                {marketType === 'ERCOT' &&
                                <FormControlLabel value="1week" label="1 Week"
                                    control={<Radio onClick={handlePredChange} icon={<CircleOutlinedIcon sx={{ color: "black" }} />} checkedIcon={<CheckCircleIcon sx={{ color: "black" }} />} />} />
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </FormControl>
            </RadioGroup>
            <RadioGroup
                value={historicalState}
            >
                <FormControl>
                    <Card elevation={0} sx={{ maxWidth: 650 }}>
                        <CardHeader
                            title="Historical Window"
                        />
                        <Divider color="black" sx={{ borderBottomWidth: 3, color: "black" }} />
                        <CardContent>
                            <Grid container item xs={6} direction="column" >
                                {marketType === 'AESO' &&
                                <FormControlLabel value="24hour_historical" label="24 Hour"
                                    control={<Radio onClick={handleHistoricalChange} icon={<CircleOutlinedIcon sx={{ color: "black" }} />} checkedIcon={<CheckCircleIcon sx={{ color: "black" }} />} />} />
                                }

                                <FormControlLabel value="1week_historical" label="1 Week"
                                    control={<Radio onClick={handleHistoricalChange} icon={<CircleOutlinedIcon sx={{ color: "black" }} />} checkedIcon={<CheckCircleIcon sx={{ color: "black" }} />} />} />
                                <FormControlLabel value="2weeks_historical" label="2 Weeks"
                                    control={<Radio onClick={handleHistoricalChange} icon={<CircleOutlinedIcon sx={{ color: "black" }} />} checkedIcon={<CheckCircleIcon sx={{ color: "black" }} />} />} />
                                <FormControlLabel value="1month_historical" label="30 Days"
                                    control={<Radio onClick={handleHistoricalChange} icon={<CircleOutlinedIcon sx={{ color: "black" }} />} checkedIcon={<CheckCircleIcon sx={{ color: "black" }} />} />} />
                            </Grid>
                        </CardContent>
                    </Card>
                </FormControl>
            </RadioGroup>

            <Divider color="black" sx={{ borderBottomWidth: 3, color: "black", marginTop: "45px", marginBottom: "40px" }} />
            <Grid container spacing={4} >
                <Grid container item xs={12} direction="column" >
                    <Button variant="contained" onClick={handleSubmit(false)} sx={{ width: "500px", padding: "10px", marginLeft: "80px", marginTop: "20px", borderRadius: "13px" }}>Submit</Button>
                    <Button variant="outlined" onClick={toggleDrawer(false)} sx={{ width: "500px", padding: "10px", marginLeft: "80px", marginTop: "20px", borderRadius: "13px" }}>Cancel</Button>
                </Grid>
            </Grid>
        </Box>
    );

    return (
        <div style={{ display: "inline" }}>
            <React.Fragment key={'Filter'}>
                <Button onClick={toggleDrawer(true)} sx={{ '&:hover': { backgroundColor: '#111111', boxShadow: 'none' }, color: "white", backgroundColor: "black", width: "150px", position: "relative" }}>Charts Filter</Button>
                <Drawer
                    anchor={'right'}
                    open={showForm}
                    onClose={toggleDrawer(false)}
                >
                    {content()}
                </Drawer>
            </React.Fragment>
        </div>
    );
};

export default DataFilter;