import * as React from 'react';
import { Container, Grid, Box, Divider, Paper} from '@mui/material';
import { Typography } from '@material-ui/core';
import spark_map from "../../static/spark_map.png";
import dataProcess from "../../static/dataProcess.png";
import sparklogo from "../../static/SPARK-removebg-preview.png";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DataArrayIcon from '@mui/icons-material/DataArray';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import InsightsIcon from '@mui/icons-material/Insights';
import PeopleIcon from '@mui/icons-material/People';



const LandingPage: React.FunctionComponent = () => {
    const renderLandingPage = () => {
        return (
            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ '& > :not(style)': { m: 1 }, textAlign: "Left", postion: "abolute", opacity: "100%" }}>
                <Grid container spacing={1} style={{}}>
                    <Paper elevation={5} style={{minWidth: "-webkit-fill-available", backgroundColor: "#0B3050", borderRadius: "35px"}}>
                    <Grid container spacing={2} item xs = {12} style={{backgroundColor:"", marginLeft: "8rem", marginTop: "5rem", marginBottom: "5rem"}}>
                        <Grid item xs = {5} style={{position: "relative", paddingBottom: "40px", paddingTop: "40px"}}>
                                <Typography style={{fontSize: "30px", fontWeight: "lighter", textAlign: "left", color:"#5AA3D2", marginLeft:"0px"}}>
                                What is
                                </Typography>
                                <Typography style={{fontSize: "100px", fontWeight: "400", textAlign: "left", color:"white", marginLeft:"0px", marginTop: "-25px", marginBottom: "25px"}}>
                                SPARK? 
                                </Typography>
                                <Typography style={{fontSize: "25px", fontWeight: "200", textAlign: "justify", lineHeight: "42px", color: "rgb(192 192 192)"}}>
                                SPARK is a unique solution developed through a collaboration between Enterprise Innovation, Power & Energy Solutions, and Information Services to develop an analytics platform for short term forecasting that utilizes proven machine learning and artificial intelligence techniques available through Amazon Web Services. 
                                </Typography>
                        </Grid>
                        
                        <Grid item xs = {6} style={{textAlign: "right", paddingTop: "70px", marginLeft: "-150px"}}>
                            <Typography style={{fontSize: "25px", lineHeight: "42px"}}>
                            <img src={sparklogo} style={{maxHeight:"70rem", position: "relative", borderRadius: "0px", height: "33rem", marginTop: "-5rem", marginBottom: "-10rem"}}/>
                            </Typography>
                        </Grid>
                    </Grid>
                    </Paper>
                    

                    <Grid item xs={12}>
                        <Divider color="black" sx={{ borderBottomWidth: 4, padding: "1px", marginBottom: "40px", marginTop: "40px", border: "20px"}} />
                    </Grid>

                    <Paper elevation={5} style={{minWidth: "-webkit-fill-available", backgroundColor: "#0B3050", borderRadius: "35px"}}>
                    <Grid container spacing={2} item xs = {12} style={{paddingBottom: "40px", paddingTop: "40px"}}>
                        <Grid item xs = {7} style={{position: "relative"}}>
                                <Typography style={{fontSize: "30px", fontWeight: "lighter", textAlign: "left", color:"#5AA3D2", marginLeft:"8rem"}}>
                                    Primary 
                                </Typography>
                                <Typography style={{fontSize: "100px", fontWeight: "400", textAlign: "left", color:"white", marginLeft:"8rem", marginTop: "-25px", marginBottom: "60px"}}>
                                    Objectives 
                                </Typography>
                                <Typography style={{fontSize: "30px", lineHeight: "42px", marginLeft:"6rem", textAlign: "left", marginBottom: "45px", color: "rgb(192 192 192)"}}>
                                    <InsightsIcon style={{top: "15px" , right: "10px", position: "relative", fontSize: "55px", color: "#A7BB65" }}/> Forecast commodity prices to enable trading strategies  
                                </Typography>
                                <Typography style={{fontSize: "30px", lineHeight: "42px", marginLeft:"6rem", textAlign: "left", marginBottom: "45px", color: "rgb(192 192 192)"}}> 
                                    <MoreTimeIcon style={{top: "15px" , right: "10px", position: "relative", fontSize: "55px", color: "#A7BB65" }}/> Make timely decisions on energy transactions and contracts 
                                </Typography>
                                <Typography style={{fontSize: "30px", lineHeight: "42px", marginLeft:"6rem", textAlign: "left", marginBottom: "45px", color: "rgb(192 192 192)"}}> 
                                    <DesignServicesIcon style={{ top: "15px" , right: "10px", position: "relative",fontSize: "55px", color: "#A7BB65" }}/> User experience design and a citizen development approach
                                </Typography>
                        </Grid>
                        
                        <Grid item xs = {5} style={{textAlign: "right", paddingTop: "70px", marginLeft: "-100px"}}>
                            <Typography style={{fontSize: "25px", lineHeight: "42px"}}>
                                <img src={spark_map} style={{maxHeight:"50rem", borderRadius: "15px", width: "55vh"}}/>
                            </Typography>
                        </Grid>
                    </Grid>
                    </Paper>
                    
                    <Grid item xs={12}>
                        <Divider color="black" sx={{ borderBottomWidth: 4, padding: "1px", marginBottom: "40px", marginTop: "40px", border: "20px"}} />
                    </Grid>
                    
                    <Paper elevation={5} style={{minWidth: "-webkit-fill-available", backgroundColor: "#0B3050", borderRadius: "35px"}}>
                            <Grid item xs = {12} style={{paddingBottom: "40px", paddingTop: "40px"}}>
                                <Typography style={{fontSize: "30px", fontWeight: "lighter", textAlign: "center", color:"#5AA3D2", marginLeft:"0px"}}>
                                    SPARK 
                                </Typography>
                                <Typography style={{fontSize: "100px", fontWeight: "400", textAlign: "center", color:"white", marginLeft:"0px", marginTop: "-25px"}}>
                                    Data Process 
                                    <br>
                                    </br>
                                    <img src={dataProcess} style={{maxHeight:"70rem", borderRadius: "15px", width: "75vh", alignContent: "center", marginTop: "25px"}}/>
                                </Typography>                                    
                            </Grid>   
                    </Paper>
                    

                    <Grid item xs={12}>
                        <Divider color="black" sx={{ borderBottomWidth: 4, padding: "1px", marginBottom: "40px", marginTop: "40px", border: "20px"}} />
                    </Grid>
                    
                    <Paper elevation={5} style={{minWidth: "-webkit-fill-available", backgroundColor: "#0B3050", borderRadius: "35px"}}>
                        <Grid container spacing={2} item xs = {12} style={{paddingBottom: "40px", paddingTop: "40px", paddingRight: "40px"}}>
                            <Grid item xs = {12} style={{marginLeft: "8rem", marginTop: "5rem"}}>
                                <Typography style={{fontSize: "30px", fontWeight: "lighter", textAlign: "left", color:"#A7BB65", marginLeft:"0px"}}>
                                Benefits for
                                </Typography>
                                <Typography style={{fontFamily: "", fontSize: "100px", fontWeight: "400", textAlign: "left", color:"white", marginLeft:"0px", marginTop: "-25px"}}>
                                Power & Energy Solutions
                                </Typography>
                                
                            </Grid>
                            
                            <Grid item xs = {3} style={{textAlign: "center", paddingTop: "70px", paddingLeft: "50px"}}>
                                <DataArrayIcon style={{fontSize: "45px", color: "#A7BB65"}}/>
                                <Typography style={{fontSize: "45px", color: "#5AA3D2"}}>
                                    Data
                                </Typography>
                                <Grid item xs={12}>
                                    <Divider color="white" sx={{ borderBottomWidth: 4, padding: "1px", marginBottom: "15px" ,marginTop: "15px", opacity: "40%", border: "30px"}} />
                                </Grid>
                                <Typography style={{fontSize: "25px", fontWeight: "200", paddingLeft: "15px", lineHeight: "50px", textAlign: "justify", textAlignLast: "center", color: "rgb(192 192 192)"}}>
                                    TC specific data feeds enable efficient integration to existing process and tools, with no limits to data views and usage which is case with 3rd party data providers 
                                </Typography>
                            </Grid>
                            <Grid item xs = {3} style={{textAlign: "center", paddingTop: "70px", paddingLeft: "50px"}}>
                                <MoreTimeIcon style={{fontSize: "45px", color: "#A7BB65"}}/>
                                <Typography style={{fontSize: "45px", color: "#5AA3D2" }}>
                                    Real-time
                                </Typography>
                                <Grid item xs={12}>
                                    <Divider color="white" sx={{ borderBottomWidth: 4, padding: "1px", marginBottom: "15px" ,marginTop: "15px", opacity: "40%", border: "30px"}} />
                                </Grid>
                                <Typography style={{fontSize: "25px", fontWeight: "200", paddingLeft: "15px", lineHeight: "50px", textAlign: "justify", textAlignLast: "center", color: "rgb(192 192 192)"}}>  
                                    Short-term predictions are updated on a real-time basis to capture the dynamic nature of the market fundamentals based the most recent available information
                                </Typography>
                            </Grid>
                            <Grid item xs = {3} style={{textAlign: "center", paddingTop: "70px", paddingLeft: "50px"}}>
                                <InsightsIcon style={{fontSize: "45px", color: "#A7BB65"}}/>
                                <Typography style={{fontSize: "45px", color: "#5AA3D2" }}>
                                    Flexibility
                                </Typography>
                                <Grid item xs={12}>
                                    <Divider color="white" sx={{ borderBottomWidth: 4, padding: "1px", marginBottom: "15px" ,marginTop: "15px", opacity: "40%", border: "30px"}} />
                                </Grid>
                                <Typography style={{fontSize: "25px", fontWeight: "200", paddingLeft: "15px", lineHeight: "50px", textAlign: "justify", textAlignLast: "center", color: "rgb(192 192 192)"}}>  
                                    Designed to be extensible taking future growth into consideration by anticipating the need to add new functionality for other commodities and markets
                                </Typography>
                            </Grid>
                            <Grid item xs = {3} style={{textAlign: "center", paddingTop: "70px", paddingLeft: "50px"}}>
                                <PeopleIcon style={{fontSize: "45px", color: "#A7BB65"}}/>
                                <Typography style={{fontSize: "45px", color: "#5AA3D2" }}>
                                    User Experience
                                </Typography>
                                <Grid item xs={12}>
                                    <Divider color="white" sx={{ borderBottomWidth: 4, padding: "1px", marginBottom: "15px" ,marginTop: "15px", opacity: "40%", border: "30px"}} />
                                </Grid>
                                <Typography style={{fontSize: "25px", fontWeight: "200", paddingLeft: "15px", lineHeight: "50px", color: "rgb(192 192 192)", textAlign: "justify", textAlignLast: "center"}}>  
                                    User centric design of interface to capture the business needs to the end-users
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Grid item xs={12}>
                        <Divider color="black" sx={{ borderBottomWidth: 4, padding: "1px", marginBottom: "40px", marginTop: "40px", border: "20px"}} />
                    </Grid>

                    <Paper elevation={5} style={{minWidth: "-webkit-fill-available", backgroundColor: "#0B3050", borderRadius: "35px"}}>
                            <Grid item xs = {10} style={{marginLeft: "8rem", marginTop: "5rem", marginBottom: "5rem"}}>
                                <Typography style={{fontSize: "100px", fontWeight: "400", textAlign: "justify", color:"white", marginLeft:"15px"}}>
                                    Result
                                </Typography>      
                                <Typography style={{fontSize: "25px", textAlign: "justify", paddingLeft: "15px", lineHeight: "50px", color: "rgb(192 192 192)"}}>
                                With the power of AI and machine learning in our hands, we are using advanced analytics, coupled with our industry expertise and cross-commodity data, to deliver new insights on commodity trading. This is a multi-phased opportunity for IS and CMA to collaborate and deliver TC Energy a competitive advantage! 
                                </Typography>                              
                            </Grid>   
                    </Paper>

                </Grid>     
                </Box>
            </Container>
         );
        };         
    return renderLandingPage();
};



export default LandingPage;