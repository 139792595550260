import { Typography } from '@material-ui/core';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import Box from '@mui/material/Box';
import React from "react";
import { Divider } from '@mui/material';

export interface InformationCardProperties {
  RTLastUpdatedValue?: string;
  DALastUpdatedValue?: string;
  balanceDayPrice?: string;
  nextDayOffPeakPrice?: string;
  nextDayPeakPrice?: string;
}

const InformationCard: React.FunctionComponent<InformationCardProperties> = ({ RTLastUpdatedValue, DALastUpdatedValue, balanceDayPrice, nextDayOffPeakPrice, nextDayPeakPrice }:
  InformationCardProperties) => {
  return (
    <div>
      <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
        PRICE
      </Typography>
      <Divider color="black" sx={{ borderBottomWidth: 4, padding: "2.5px",border: "30px" }}></Divider>
      <div>
        <Box sx={{ typography: 'body1', display: "flex"}}>
          {(balanceDayPrice !== "(Loading...)") && <Box sx={{ typography: 'subtitle2', paddingTop: "15px", paddingLeft: "25px", fontSize: "2rem", fontWeight: "Bold" }}>
            <AttachMoneyOutlinedIcon />{balanceDayPrice}
          </Box>}
          {(balanceDayPrice === "(Loading...)") && <Box sx={{ typography: 'subtitle2', paddingTop: "15px", paddingLeft: "25px", fontSize: "1rem", fontWeight: "Bold" }}>
            <AttachMoneyOutlinedIcon />{balanceDayPrice}
          </Box>}

          {(nextDayPeakPrice !== "(Loading...)") && <Box sx={{ typography: 'subtitle2', paddingTop: "15px", paddingLeft: "150px", fontSize: "2rem", fontWeight: "Bold" }}>
            <AttachMoneyOutlinedIcon />{nextDayPeakPrice}
            {/* <Typography style={{ fontWeight: "bold", fontSize: "1rem" }}>
              {DALastUpdatedValue}
            </Typography> */}
          </Box>}
          {(nextDayPeakPrice === "(Loading...)") && <Box sx={{ typography: 'subtitle2', paddingTop: "15px", paddingLeft: "150px", fontSize: "1rem", fontWeight: "Bold" }}>
            <AttachMoneyOutlinedIcon />{nextDayPeakPrice}
          </Box>}

          {(nextDayOffPeakPrice !== "(Loading...)") && <Box sx={{ typography: 'subtitle2', paddingTop: "15px", paddingLeft: "170px", fontSize: "2rem", fontWeight: "Bold" }}>
            <AttachMoneyOutlinedIcon />{nextDayOffPeakPrice}
          </Box>}
          {(nextDayOffPeakPrice === "(Loading...)") && <Box sx={{ typography: 'subtitle2', paddingTop: "15px", paddingLeft: "150px", fontSize: "1rem", fontWeight: "Bold" }}>
            <AttachMoneyOutlinedIcon />{nextDayOffPeakPrice}
          </Box>}
        </Box>
        <Box sx={{ typography: 'subtitle2', display: "flex" }}>
          <Box sx={{ typography: 'subtitle2', fontSize: "0.8rem", paddingLeft: "32px", paddingBottom: "2rem" }}>
            Bal Day (RT)
          </Box>
          <Box sx={{ typography: 'subtitle2', fontSize: "0.8rem", paddingLeft: "180px" }}>
            Next Day - Peak (DA)
          </Box>
          <Box sx={{ typography: 'subtitle2', fontSize: "0.8rem", paddingLeft: "150px" }}>
            Next Day - Off-Peak (DA)
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default InformationCard;