import React from "react";
import { Container, Grid, Paper, Box } from '@mui/material';
import InformationCard from "../DashboardAeso/components/informationcard";
import PowerDemandChart from "../DashboardAeso/components/powerdemandchart";
import WindAndSolarChart from "../DashboardAeso/components/windandsolarchart";
import RTPriceChart from "../DashboardAeso/components/RTpricechart";
import NetDemandChart from "../DashboardAeso/components/netdemandchart";
import SupplyCushionChart from "../DashboardAeso/components/supplycushionchart";
import AvailableTransferCapacityChart from "../DashboardAeso/components/availabletransfercapacitychart";
// import GasPriceChart from "../DashboardAeso/components/gaspricechart";
import OutageChart from "../DashboardAeso/components/outagechart";
import Timer from "../DashboardAeso/components/Timer";
import DashboardService from "../../services/DashboardService";
import type { RTData, RTReportModel } from "../../datamodel/model"
import Datafilter from "../DashboardAeso/components/datafilter/datafilter";
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import Fab from '@mui/material/Fab';
import moment from "moment";

const DashboardAeso: React.FunctionComponent = () => {
    /**
     * States
     */
    const [rtData, setRTData] = React.useState<RTData[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    // filter's choice, default is 24 hours frontward and backward for rt data and 1 week backward for historial data
    const [userFilterChoice, setUserFilterChoice] = React.useState<any>({ state: "24hours&24hour_historical" });
    const [RTReportData, setRTReportData] = React.useState<RTReportModel[]>([]);
    const [RTReportStatus, setRTReportStatus] = React.useState<string>('SUCCESS');

    /**
     * 
     * @param history_choice days backwards for historical data
     * @param prediction_choice days forwards for prediction
     * @returns data as a dataframe from restapilambda lambda function on AWS
     */
    const fetchData = async (history_choice: string, prediction_choice: string) => {
        let result = await Promise.all([
            DashboardService.getFilteredData("aeso", "null", "null", history_choice, prediction_choice)
        ])
        result[0].rt_data= result[0].rt_data.sort((a:any, b:any) => b.DateTime < a.DateTime ? 1: -1);
        // console.log("RESULT FROM PROMISE: ", result)
        return result
    }

    /**
     * This part is responsible for intial data fetch when loading the page
     */
    React.useEffect(() => {

        const initDataFetch = async (history_choice: string, prediction_choice: string) => {
            const res: [any] = await fetchData(history_choice, prediction_choice);
            res[0].rt_data.forEach((element: any) => {
                element['DateTime'] = new Date(element.DateTime.slice(0, -1))
            })
            //console.log(res[0].rt_data)
            setRTData(res[0].rt_data)
            setRTReportData(res[0].rt_report_data)
            //console.log(res[0].rt_report_data)
            // console.log("Promise done")
            // console.log(res)
        };
        initDataFetch('1week', '24hours');
    }, []);

    /**
     * This part runs when the page is loading, and data is already stored in the state
     */
    React.useEffect(() => {
        if (isLoading === true && rtData.length !== 0) {

            // console.log("All data is set")
            console.log(rtData)
            setIsLoading(false);
        }
        else if (isLoading === true) {
            console.log("Waiting")
            console.log(rtData)

        }

    }, [rtData, isLoading, userFilterChoice]);

    /**
     * This part runs when user changes the datetime preference from the filter choice button, fetch data from backend restapilambda again
     */
    React.useEffect(() => {

        /**
         * 
         * @param state not used, use userFilterChoice
         */
        const fetchFilteredDate = async (state: any) => {
            if (isLoading === false) {
                let res: any = null
                console.log('Filtering, Now showing data: ', userFilterChoice.state)

                if (userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week")
                    res = await fetchData("None", userFilterChoice.state);
                else if (userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical")
                    res = await fetchData(userFilterChoice.state.split('_')[0], "None");

                else {
                    const userChoiceArray = userFilterChoice.state.split('&')
                    res = await fetchData(userChoiceArray[1].split('_')[0], userChoiceArray[0]);
                }
                res[0].rt_data.forEach((element: any) => {
                    element['DateTime'] = new Date(element.DateTime.slice(0, -1))
                })
                setRTData(res[0].rt_data)
            }
        }
        fetchFilteredDate(userFilterChoice.state)
    }, [userFilterChoice, isLoading]);

    /**
     * When Report data is updated in the state, update UI accordingly
     */
    React.useEffect(() => {
        if (RTReportData[0]) {
            // setRTLastUpdatedValue(`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`)
            setRTReportStatus(`${RTReportData[0].STATUS}`)
        }
        localStorage.setItem('last_tab_visited', '3');
    }, [RTReportData])

    const renderDashBoard = () => {
        return (
            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ '& > :not(style)': { m: 1 }, textAlign: "center", postion: "abolute", opacity: "55%" }}>
                    <Fab variant="extended">
                        <UpdateRoundedIcon sx={{ mr: 1 }} />
                        <Box>
                            <Timer updatedTime={`(Last Updated - ${moment(RTReportData[0].NEXT_UPDATED).format('l, h:mm:ss a')})`} />
                        </Box>
                    </Fab>
                </Box>
                <Grid container spacing={3} style={{ marginLeft: "-55px" }}>
                    {/* information Card */}
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 140,
                            }}
                            elevation={0}
                        >
                            
                            
                            <InformationCard RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} balanceDayPrice={RTReportData[0].BALANCE_DAY===undefined ? `NULL`:`${RTReportData[0].BALANCE_DAY.toFixed(2)}`} sameDayPrice={RTReportData[0].SAME_DAY===undefined ? `NULL`:`${RTReportData[0].SAME_DAY.toFixed(2)}`}/>
                        </Paper>
                    </Grid>

                    {/* RT price demand chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop: "30px" }} elevation={0} >
                            <Box sx={{ display: '-ms-inline-flexbox' }}>
                                <Box sx={{ marginLeft: "91%" }}>
                                    <Datafilter userCurrentChoice={userFilterChoice} setUserFilterChoice={setUserFilterChoice} maketType='AESO'></Datafilter>
                                </Box>
                                <Box>
                                    {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <RTPriceChart RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                                    {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <RTPriceChart RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                                    {(userFilterChoice.state.includes('&') === true) && <RTPriceChart RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}

                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    {/* gas price chart */}
                    {/* <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop: "30px" }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <GasPriceChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <GasPriceChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <GasPriceChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid> */}
                    {/* power demand chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <PowerDemandChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <PowerDemandChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <PowerDemandChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                    {/* wind and solar chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <WindAndSolarChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <WindAndSolarChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <WindAndSolarChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                    {/* outage chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <OutageChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <OutageChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <OutageChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                    {/* net demand chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop: "30px" }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <NetDemandChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <NetDemandChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <NetDemandChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                    {/* supply cushion chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop: "30px" }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <SupplyCushionChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <SupplyCushionChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <SupplyCushionChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                    {/* available transfer capacity chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop: "30px" }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <AvailableTransferCapacityChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <AvailableTransferCapacityChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <AvailableTransferCapacityChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    };

    return isLoading ? <>Loading...</> : renderDashBoard();
};

export default DashboardAeso;