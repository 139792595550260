import moment from "moment";
/**
 * 
 * @param tickItem DateTime string to be formatted
 * @returns formatted variable
 */
function formatXAxis(tickItem:string){
    return moment(tickItem).format('MM/DD HH:mm');
  }
export default formatXAxis
