import React from 'react'
import { Typography, Box } from "@material-ui/core";
export interface props {
    updatedTime: any;
}


const Timer: React.FunctionComponent<props> = ({ updatedTime }) => {
    const timeUpdate = new Date(updatedTime.split(' ').slice(3, 6).join(" ").slice(0, -1));
    // Give it a 5 min buffer zone to refresh
    const nextUpdateCSTTime = new Date(timeUpdate.setTime(timeUpdate.getTime() + 3600000 + 300000)).toLocaleString('en-US', {
        // timeZone: 'CST', // No need to use CST, nextUpdateTime is already set in CST time.
    })
    const nextUpdateTime: any = new Date(nextUpdateCSTTime);
    const currentCSTTime = new Date().toLocaleString('en-US', {
        timeZone: 'CST',
    })
    const currentTime: any = new Date(currentCSTTime);

    const timeDiff: any = nextUpdateTime - currentTime
    const initialMinute = Math.floor((timeDiff / 1000) / 60)
    const initialSeconds = Math.floor((timeDiff / 1000) % 60)


    const [minutes, setMinutes] = React.useState<number>(initialMinute);
    const [seconds, setSeconds] = React.useState<number>(initialSeconds);
    React.useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                    window.location.reload();

                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <div>
            {minutes === 0 && seconds === 0
                ? null
                :
                <Box alignItems="center">
                    <Box>
                        <Typography style={{ fontSize: "1rem", color: "black", paddingRight: "22px" }}>
                            Next Update in:
                        </Typography>
                    </Box>
                    <Box>
                        {minutes >= 0 && seconds >= 0 && <Typography style={{ fontWeight: "bold", fontSize: "1.15rem", color: "black" }}>
                            {minutes} {minutes > 1 ? `mins` : `min`} : {seconds < 10 ? `0${seconds}` : seconds} {seconds > 1 ? `seconds` : `second`}
                        </Typography>}
                    </Box>

                </Box>

            }
        </div >
    )
}

export default Timer;