import { formatISO } from "date-fns";

class Log {
  static debug(message: string): void {
    console.debug(`${formatISO(new Date())} - ${message}`);
  }

  static warn(message: string): void {
    console.warn(`${formatISO(new Date())} - ${message}`);
  }

  static error(message: string): void {
    console.error(`${formatISO(new Date())} - ${message}`);
  }

  static log(message: string): void {
    console.log(`${formatISO(new Date())} - ${message}`);
  }
}

export default Log;
