import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";

import Log from "../utils/Log";
import { getTcAccessToken } from "../auth/utils";
import { NETWORK_ERROR } from "../utils/Toaster/Constants";
import { parseISO } from "date-fns";
export interface HttpError {
  status: number;
  message: string;
}

/**
 * Handles api error for the response interceptor by:
 *   - Logs the error to the Log.error function
 *   - Displays toast with the error status and message
 *   - passes the HttpError through the promise via reject
 *
 * note: defaults the error status to 400 and message to Bad Request when the interceptor does not get valid error message or status;
 */
const handleError = (errMessage: string, errCode: number, url: string) => {
  const status = errCode || 400;
  const message = errMessage || "Bad Request";

  const logMessage = `${status}: ${message}`;
  Log.error(logMessage);
  toast.error(`${NETWORK_ERROR} (${logMessage})`, { toastId: `${url}-errorToast` });

  const error: HttpError = { status, message };
  return Promise.reject(error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseResponse = (response: any) => {
  return response;
};

const baseConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
};

const http = axios.create(baseConfig);

http.interceptors.request.use((config) => {
  const tempConfig = config;
  // console.log(getTcAccessToken());
  tempConfig.headers!.Authorization = `Bearer ${getTcAccessToken()}`;
  return tempConfig;
});

http.interceptors.response.use(
  (response) => {
    handleDates(response.data);
    return parseResponse(response);
  },
  (error) => {
    return handleError(error.response?.data.message, error.response?.status, error.config?.url);
  }
);


const isoDateFormat = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && isoDateFormat.test(value);
}

// source refer: https://stackoverflow.com/questions/65692061/casting-dates-properly-from-an-api-response-in-typescript
export function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === "object") handleDates(value);
  }
}

export default http;
