import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Typography,createStyles, Theme, makeStyles } from "@material-ui/core";
import mySvg from "../../static/sparkLogo.svg";
import sparklogo from "../../static/SPARK-removebg-preview.png";
import BasicPopover from './Popover/Popover';



const drawerWidth = 130;

export interface LocationMenuItem {
  /** name of the nav item, NAV_DIVIDER adds a divider item */
  name: string | "NAV_DIVIDER";
  /** link that clicking the item will navigate the user */
  link?: string;
  /** icon before name, should be used only at top level */
  icon?: React.ReactNode;
  /** opens links in new tab onClick */
  externalLink?: boolean;
  /** hover text */
  tooltip?: string;
  /** The unique HTML id is used to locate element on page for automated testing */
  id?: string;
  /** Disables the item in the list */
  disabled?: boolean;
}
export interface NavMenuItem {
  /** name of the nav item, NAV_DIVIDER adds a divider item */
  name: string | "NAV_DIVIDER";
  /** link that clicking the item will navigate the user */
  link?: string;
  /** icon before name, should be used only at top level */
  icon?: React.ReactNode;
  /** opens links in new tab onClick */
  externalLink?: boolean;
  /** hover text */
  tooltip?: string;
  /** The unique HTML id is used to locate element on page for automated testing */
  id?: string;
  /** Disables the item in the list */
  disabled?: boolean;
}



const navMenu: NavMenuItem[] = [
  
  {
    name: "Alerts",
    id: "link-alert-view",
    icon: <NotificationsActiveOutlinedIcon style={{ fontSize: "2.5rem" }} />,
  },
  {
    name: "Settings",
    id: "link-settings-view",
    icon: <SettingsOutlinedIcon style={{ fontSize: "2.5rem" }}/>,
  },
  {
    name: "Logout",
    id: "link-logout-view",
    icon: <LogoutIcon style={{ rotate: "180deg", fontSize: "2.5rem" }} />,
  }
];


const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    drawer: {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    subtitleText: {
      paddingLeft: "2.5rem",
      verticalAlign: "center"
    },
    logo: {
      maxHeight: "30rem",
      height: "4.1rem",
      paddingLeft: "1rem",
      // paddingBottom: "0.2rem"
    },
  })
);

/**
 * 
 * @returns FunctionComponent
 */
const Navigation: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: 'black', color: 'white' },
      }}
    >
      <Toolbar style={{ minHeight: '0px' }}></Toolbar>
      <Box
        sx={{
          overflow: 'auto'
        }}
      >
        <List>
          <img src={sparklogo} alt="tc energy logo" className={classes.logo} style={{height: "6.1rem"}}/>
          <Typography style={{ fontWeight: "bold", fontSize: "0.56rem", color:"white",paddingBottom:"30px", marginLeft:"15px", marginRight:"15px", textAlign: "justify", textAlignLast: "center"}}>
          © Power and Energy Solutions - Commercial Marketing Analytics
          </Typography>
        </List>
        
        <BasicPopover/>

        <List>
          
          {navMenu.map((item, index) => (
            
            <ListItem key={item.name} style={{ color: "white", paddingLeft: -2, verticalAlign: "text-bottom" }} disablePadding>
              <ListItemButton>
                <ListItemIcon className={classes.subtitleText} style={{ color: "white", verticalAlign: "text-bottom", marginLeft: "-10px", }}>
                  {item.icon} <br></br>
                  <div style={{ marginLeft: "-2.65rem", marginTop: "4rem", fontSize: "17px" }}>{item.name}</div>
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Navigation;