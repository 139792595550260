import React from 'react';

import * as ReactDOM from 'react-dom/client';
import { ThemeProvider as MuiThemeProvider, StylesProvider } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";

import { AuthProvider } from "react-oidc-context";

import App from './app/App';

import theme from "./theme";
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(
  document.getElementById('root')!
);

const oidcConfig = {
  authority: process.env.REACT_APP_VALID_ISSUER!,
  client_id: process.env.REACT_APP_VALID_CLIENT_ID!,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL!,
};

root.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider {...oidcConfig}>
            <BrowserRouter>
            <App />
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
