import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Button, Divider, Card } from '@mui/material';
import AxisLabel from '../yaxisLabel'
import formatXAxis from "../xaxisFormat";
import moment from "moment";
import { Typography } from "@material-ui/core";

export interface powerDemandChartProperties {
  inputDataArray: any;
  choice: any;
  status:any;
}


const renderLegend = () => {

  return (
    <ul>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#55622b', borderColor: '#55622b', boxShadow: 'none' }, backgroundColor: "#55622b", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>Demand Actual</Button>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#799B3E', borderColor: '#799B3E', boxShadow: 'none' }, backgroundColor: "#799B3E", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>ERCOT DEMAND FORECAST</Button>
    </ul>
  );
}

const PowerDemandChart: React.FunctionComponent<powerDemandChartProperties> = ({ inputDataArray, choice,status }) => {

  const CustomTooltip = ({ active, payload, label }: any) => {

    if (active && payload && payload[0] && payload.length) {

      return (
        <Card elevation={3} className="custom-tooltip" sx={{ width: "210px" }}>
          <p className="label">&ensp;{`Date: ${moment(label).format('MM/DD - HH:mm:ss')}`}</p>
          {payload[0] && <p className="desc">&ensp;{`${payload[0].name}: ${payload[0].value.toFixed(0)}`}</p>}
          {payload[1] && <p className="desc">&ensp;{`${payload[1].name}: ${payload[1].value.toFixed(0)}`}</p>}
          {(choice === "Combined") && payload[2] && <p className="desc">&ensp;{`${payload[2].name}: ${payload[2].value.toFixed(0)}`}</p>}
          {(choice === "Combined") && payload[3] && <p className="desc">&ensp;{`${payload[3].name}: ${payload[3].value.toFixed(0)}`}</p>}

        </Card>
      );
    }

    return null;
  };

  return (
    <div>
      <Typography style={{ fontWeight: "bold", fontSize: "1.5rem", color:status!=="SUCCESS"?"red":"black"}}>DEMAND</Typography>
      <Divider color="black" sx={{ borderBottomWidth: 4, padding: "2.5px", marginBottom: "40px", border: "30px" }} />
      <ResponsiveContainer width="100%" height={450}>
        <LineChart
          width={500}
          height={400}
          data={inputDataArray}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis xAxisId="0" dataKey="DateTime"
            tickFormatter={formatXAxis}
          />

          <YAxis domain={['auto','dataMax+ 500']} label={<AxisLabel axisType="yAxis" x={25} y={175} width={0} height={0} stroke="3">Demand (MW)</AxisLabel>} />
          <Tooltip content={<CustomTooltip />} wrapperStyle={{ maxHeight: 300, width: 200, backgroundColor: '#FFFFFF' }} />
          <Legend content={renderLegend} />
          {choice === "RT" && <Line type="monotone" name="Demand Actual" dataKey="historical_load" stroke="#55622b" strokeWidth={3} dot={false} />}
          {choice === "RT" && <Line type="monotone" name="ERCOT Demand Forecast" dataKey="forecast_load" stroke="#799B3E" strokeWidth={2} dot={false} />}
          {choice === "Historical" && <Line type="monotone" name="Demand Actual" dataKey="load_actual" stroke="#55622b" strokeWidth={3} dot={false} />}
          {choice === "Historical" && <Line type="monotone" name="ERCOT Demand Forecast" dataKey="load" stroke="#799B3E" strokeDasharray="4 1 2" strokeWidth={2} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="ERCOT Demand Forecast" dataKey="historical_load" stroke="#799B3E" strokeDasharray="4 1 2" strokeWidth={2} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="Demand Actual" dataKey="load_actual" stroke="#55622b" strokeWidth={3} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="ERCOT Demand Forecast" dataKey="forecast_load" stroke="#799B3E" strokeWidth={2} dot={false} />}

        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PowerDemandChart;