import React, { useEffect } from "react";
import { Box } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import DashboardErcot from "../domain/DashboardErcot/DashboardErcot";
import DashboardAeso from "../domain/DashboardAeso/DashboardAeso";
import DashboardReports from "../domain/DashboardReports/DashboardReports";
import LandingPage from "../LandingPage/LandingPage";
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { Typography } from "@material-ui/core";

const Body: React.FunctionComponent = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    useEffect(()=>{
        if(localStorage.getItem('last_tab_visited') !== null){
            setValue(localStorage.getItem('last_tab_visited')!);
        }
    },[]);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, padding: "5px", backgroundColor: 'white' }}>
            <Typography style={{ fontWeight: "500", fontSize: "2rem", color: "black", paddingLeft: "20px" }}>
                Dashboard
            </Typography>
            <Toolbar style={{ minHeight: "15px" }} />
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                        <Tab label="SPARK" value="1" />
                        <Tab label="ERCOT" value="2" />
                        <Tab label="Alberta" value="3" />
                        <Tab label="Reports" value="4" />
                        {/* <Tab label="SPP" value="4" />
                        <Tab label="PJM" value="5" />
                        <Tab label="Miso" value="6" /> */}
                    </TabList>
                </Box>
                <TabPanel value="1" style={{ padding: "0px" }}>
                    <LandingPage />
                </TabPanel>
                <TabPanel value="2" style={{ padding: "0px" }}>
                    <DashboardErcot />
                </TabPanel>
                <TabPanel value="3" style={{ padding: "0px" }}>
                    <DashboardAeso />
                </TabPanel>
                <TabPanel value="4" style={{ padding: "0px" }}>
                    <DashboardReports />
                </TabPanel>
                {/* <TabPanel value="4">
                    SPP
                </TabPanel>
                <TabPanel value="5">
                    PJM
                </TabPanel>
                <TabPanel value="6">
                    Miso
                </TabPanel> */}
            </TabContext>
        </Box>
    );
};

export default Body;