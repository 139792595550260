import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";

//import Header from "./Header";
import Navigation from "./Navigation";
import Body from "./Body";

import {useSearchParams} from 'react-router-dom';

import { useAuth, hasAuthParams } from "react-oidc-context";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
    },
  })
);

const App: React.FunctionComponent = () => {
  const classes = useStyles();

  const auth = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  
  // automatically sign-in
  React.useEffect(() => {
    if (!hasAuthParams() &&
        !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
        auth.signinRedirect();
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);
 
  if (auth.activeNavigator) {
    return <div>Signing you in/out...</div>;
  }
  if(!auth.isAuthenticated) {
     return <div>Unable to log in</div>;
  }

   // remove code from url   
   if (searchParams.has('code')) {
    searchParams.delete('code');
    setSearchParams(searchParams);
  }

  // remove state from url   
  if (searchParams.has('state')) {
    searchParams.delete('state');
    setSearchParams(searchParams);
  }

  return (
    <div className={classes.root}>
      <Navigation />
      <Body />
    </div>
  );
  
}

export default App;
