import React from "react";
import { Container, Grid, Paper, Box } from '@mui/material';
import InformationCard from "./components/informationcard";
import PowerDemandChart from "./components/powerdemandchart";
import WindAndSolarChart from "./components/windandsolarchart";
import RTPriceChart from "./components/RTpricechart";
import DAPriceChart from "./components/DApricechart";
import DARTChart from "./components/DARTchart";
import GasPriceChart from "./components/gaspricechart";
import OutageChart from "./components/outagechart";
import Timer from "./components/Timer";
import DashboardService from "../../services/DashboardService";
import type { RTData, DAData, DAReportModel, RTReportModel } from "../../datamodel/model"
import Datafilter from "./components/datafilter/datafilter";
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import Fab from '@mui/material/Fab';
import moment from "moment";

const DashboardErcot: React.FunctionComponent = () => {

    /**
     * States
     */
    const [rtData, setRTData] = React.useState<RTData[]>([]); // rt data
    const [daData, setDAData] = React.useState<DAData[]>([]); // da data
    const [isLoading, setIsLoading] = React.useState(true); // see if page is loaded already
    const [userFilterChoice, setUserFilterChoice] = React.useState<any>({ state: "24hours&1week_historical" }); 
    // filter's choice, default is 24 hours frontward and backward for rt data and 1 week backward for historial data
    const [RTReportData, setRTReportData] = React.useState<RTReportModel[]>([]); // rt report data coming from rt/report.csv in the s3 bucket
    const [DAReportData, setDAReportData] = React.useState<DAReportModel[]>([]); // da report data coming from dam/report.csv in the s3 bucket
    const [RTReportStatus, setRTReportStatus] = React.useState<string>('SUCCESS'); // status of report
    const [DAReportStatus, setDAReportStatus] = React.useState<string>('SUCCESS'); // status of report

    /**
     * 
     * @param history_choice days backwards for historical data
     * @param prediction_choice days forwards for prediction
     * @returns data as a dataframe from restapilambda lambda function on AWS
     */
    const fetchData = async (history_choice: string, prediction_choice: string) => {
        /**
         * promise
         */
        let result = await Promise.all([
            DashboardService.getFilteredData("ercot", "north_hub", "north_hubprice", history_choice, prediction_choice)
        ])
        result[0].rt_data= result[0].rt_data.sort((a:any, b:any) => b.DateTime < a.DateTime ? 1: -1);
        result[0].da_data= result[0].da_data.sort((a:any, b:any) => b.DateTime < a.DateTime ? 1: -1);
        // console.log("RESULT FROM PROMISE: ", result)
        return result
    }

    /**
     * This part is responsible for intial data fetch when loading the page
     */
    React.useEffect(() => {

        const initDataFetch = async (history_choice: string, prediction_choice: string) => {
            const res: [any] = await fetchData(history_choice, prediction_choice);
            res[0].rt_data.forEach((element: any) => {
                element['DateTime'] = new Date(element.DateTime.slice(0, -1))
            })
            res[0].da_data.forEach((element: any) => {
                element['DateTime'] = new Date(element.DateTime.slice(0, -1))
            })
            setRTData(res[0].rt_data)
            setDAData(res[0].da_data)
            setRTReportData(res[0].rt_report_data)
            setDAReportData(res[0].da_report_data)
            // console.log("Promise done")
            // console.log(res)
        };
        initDataFetch('1week', '24hours');
    }, []);

    /**
     * This part runs when the page is loading, and data is already stored in the state
     */
    React.useEffect(() => {
        if (isLoading === true && rtData.length !== 0 && daData.length !== 0) {

            // console.log("All data is set")

            setIsLoading(false);
        }
        else if (isLoading === true) {
            console.log("Waiting")
        }

    }, [rtData, daData, isLoading, userFilterChoice]);

    /**
     * This part runs when user changes the datetime preference from the filter choice button, fetch data from backend restapilambda again
     */
    React.useEffect(() => {
        /**
         * 
         * @param state not used, use userFilterChoice
         */
        const fetchFilteredDate = async (state: any) => {
            if (isLoading === false) {
                let res: any = null
                console.log('Filtering, Now showing data: ', userFilterChoice.state)

                if (userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week")
                    res = await fetchData("None", userFilterChoice.state);
                else if (userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical")
                    res = await fetchData(userFilterChoice.state.split('_')[0], "None");

                else {
                    const userChoiceArray = userFilterChoice.state.split('&')
                    res = await fetchData(userChoiceArray[1].split('_')[0], userChoiceArray[0]);
                }
                res[0].rt_data.forEach((element: any) => {
                    element['DateTime'] = new Date(element.DateTime.slice(0, -1))
                })
                res[0].da_data.forEach((element: any) => {
                    element['DateTime'] = new Date(element.DateTime.slice(0, -1))
                })
                setRTData(res[0].rt_data)
                setDAData(res[0].da_data)
            }
        }
        fetchFilteredDate(userFilterChoice.state)
    }, [userFilterChoice, isLoading]);

    /**
     * When Report data is updated in the state, update UI accordingly
     */
    React.useEffect(() => {
        if (RTReportData[0]) {
            // setRTLastUpdatedValue(`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`)
            setRTReportStatus(`${RTReportData[0].STATUS}`)
        }
        if (DAReportData[0]) {
            // setDALastUpdatedValue(`(Last Updated - ${moment(DAReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`)
            setDAReportStatus(`${RTReportData[0].STATUS}`)
        }
        localStorage.setItem('last_tab_visited', '2');
    }, [RTReportData, DAReportData])

    const renderDashBoard = () => {
        return (
            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ '& > :not(style)': { m: 1 }, textAlign: "center", postion: "abolute", opacity: "55%" }}>
                    <Fab variant="extended">
                        <UpdateRoundedIcon sx={{ mr: 1 }} />
                        <Box>
                            <Timer updatedTime={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} />
                        </Box>
                    </Fab>
                </Box>
                <Grid container spacing={3} style={{ marginLeft: "-55px" }}>
                    {/* information Card */}
                    <Grid item xs={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 140,
                            }}
                            elevation={0}
                        >
                            <InformationCard RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} DALastUpdatedValue={`(Last Updated - ${moment(DAReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} balanceDayPrice={`${RTReportData[0].BALANCE_DAY.toFixed(2)}`} nextDayPeakPrice={`${DAReportData[0].NEXTDAY_PEAK.toFixed(2)}`} nextDayOffPeakPrice={`${DAReportData[0].NEXTDAY_OFFPEAK.toFixed(2)}`} />
                        </Paper>
                    </Grid>

                    {/* RT price demand chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop: "30px" }} elevation={0} >
                            <Box sx={{ display: '-ms-inline-flexbox' }}>
                                <Box sx={{ marginLeft: "91%" }}>
                                    <Datafilter userCurrentChoice={userFilterChoice} setUserFilterChoice={setUserFilterChoice} maketType='ERCOT'></Datafilter>
                                </Box>
                                <Box>
                                    {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <RTPriceChart RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                                    {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <RTPriceChart RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                                    {(userFilterChoice.state.includes('&') === true) && <RTPriceChart RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}

                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    {/* DA price demand chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop: "30px" }} elevation={0} >
                            <Box sx={{ display: '-ms-inline-flexbox' }}>
                                <Box sx={{ marginInlineEnd: '36px', paddingLeft: "96rem" }}>
                                </Box>
                                <Box>
                                    {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <DAPriceChart DALastUpdatedValue={`(Last Updated - ${moment(DAReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="Historical" inputDataArray={daData} status={DAReportStatus} />}
                                    {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <DAPriceChart DALastUpdatedValue={`(Last Updated - ${moment(DAReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="RT" inputDataArray={daData} status={DAReportStatus} />}
                                    {(userFilterChoice.state.includes('&') === true) && <DAPriceChart DALastUpdatedValue={`(Last Updated - ${moment(DAReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="Combined" inputDataArray={daData} status={DAReportStatus} />}
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    {/* DART chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop: "30px" }} elevation={0} >
                            <Box sx={{ display: '-ms-inline-flexbox' }}>
                                <Box sx={{ marginInlineEnd: '36px', paddingLeft: "96rem" }}>
                                </Box>
                                <Box>
                                    {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <DARTChart RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                                    {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <DARTChart RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                                    {(userFilterChoice.state.includes('&') === true) && <DARTChart RTLastUpdatedValue={`(Last Updated - ${moment(RTReportData[0].LAST_UPDATED).format('l, h:mm:ss a')})`} choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    {/* gas price chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop: "30px" }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <GasPriceChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <GasPriceChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <GasPriceChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                    {/* power demand chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <PowerDemandChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <PowerDemandChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <PowerDemandChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                    {/* wind and solar chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <WindAndSolarChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <WindAndSolarChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <WindAndSolarChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                    {/* outage chart */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }} elevation={0} >
                            {(userFilterChoice.state === "1week_historical" || userFilterChoice.state === "2weeks_historical" || userFilterChoice.state === "1month_historical") && <OutageChart choice="Historical" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state === "24hours" || userFilterChoice.state === "48hours" || userFilterChoice.state === "1week") && <OutageChart choice="RT" inputDataArray={rtData} status={RTReportStatus} />}
                            {(userFilterChoice.state.includes('&') === true) && <OutageChart choice="Combined" inputDataArray={rtData} status={RTReportStatus} />}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    };

    return isLoading ? <>Loading...</> : renderDashBoard();
};

export default DashboardErcot;