import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from "moment";
import { Button, Divider, Card} from '@mui/material';
import AxisLabel from '../yaxisLabel'
import formatXAxis from "../xaxisFormat";
import { Typography } from "@material-ui/core";

export interface gasChartProperties {
  inputDataArray: any;
  choice: any;
  status: any;
}


const renderLegend = () => {

  return (
    <ul>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#0e406a', borderColor: '#0e406a', boxShadow: 'none' }, backgroundColor: "#0e406a", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>ATC BC ACTUAL</Button>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#237cc1', borderColor: '#237cc1', boxShadow: 'none' }, backgroundColor: "#237cc1", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>ATC BC FORECAST</Button>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#0e406a', borderColor: '#0e406a', boxShadow: 'none' }, backgroundColor: "#55622b", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>ATC BC MATL ACTUAL</Button>
      <Button disableRipple sx={{ '&:hover': { backgroundColor: '#237cc1', borderColor: '#237cc1', boxShadow: 'none' }, backgroundColor: "#799B3E", fontSize: "13px", color: "white", borderRadius: "25px", padding: "5px", margin: "10px", maxBlockSize: "55px", width: "300px" }}>ATC BC MATL FORECAST</Button>
    </ul>
  );
}

const AvailableTransferCapacity: React.FunctionComponent<gasChartProperties> = ({ inputDataArray, choice, status }) => {



  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload[0] && payload.length) {

      return (
        <Card elevation={3} className="custom-tooltip" sx={{ width: "270px" }}>
          <p className="label">&ensp;{`Date: ${moment(label).format('MM/DD - HH:mm:ss')}`}</p>
          {(choice !== "Combined") && payload[0] && <p className="desc">&ensp;{`${payload[0].name}: ${payload[0].value.toFixed(2)}`}</p>}
          {(choice !== "Combined") && payload[1] && <p className="desc">&ensp;{`${payload[1].name}: ${payload[1].value.toFixed(2)}`}</p>}
          {(choice === "Combined") && payload[0] && <p className="desc">&ensp;{`${payload[0].name}: ${payload[0].value.toFixed(2)}`}</p>}
          {(choice === "Combined") && payload[1] && <p className="desc">&ensp;{`${payload[1].name}: ${payload[1].value.toFixed(2)}`}</p>}
          {(choice === "Combined") && payload[2] && <p className="desc">&ensp;{`${payload[2].name}: ${payload[2].value.toFixed(2)}`}</p>}

        </Card>
      );
    }

    return null;
  };

  return (
    <div>
      <Typography  style={{ fontWeight: "bold", fontSize: "1.5rem", color:status!=="SUCCESS"?"red":"black"}}>AVAILABLE TRANSFER CAPACITY</Typography>
      
      <Divider color="black" sx={{ borderBottomWidth: 4, padding: "2.5px", marginBottom: "40px", border: "30px" }} />
      <ResponsiveContainer width="100%" height={450}>
        <LineChart
          width={500}
          height={400}
          data={inputDataArray}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis xAxisId="0" dataKey="DateTime" tickFormatter={formatXAxis} />
          <YAxis label={<AxisLabel axisType="yAxis" x={25} y={175} width={0} height={0} stroke="3">Capacity (MW)</AxisLabel>} />
          <Tooltip content={<CustomTooltip />} wrapperStyle={{ maxHeight: 300, width: 200, backgroundColor: '#FFFFFF' }} />
          <Legend content={renderLegend} />
          {choice === "RT" && <Line type="monotone" name="ATC BC Actual" dataKey="historical_ATC_capacity_BC" stroke="#0e406a" strokeWidth={3} dot={false} />}
          {choice === "RT" && <Line type="monotone" name="ATC BC Forecast" dataKey="forecast_ATC_capacity_BC" stroke="#237cc1" strokeWidth={2} dot={false} />}
          {choice === "Historical" && <Line type="monotone" name="ATC BC Actual" dataKey="ATC_capacity_BC_actual" stroke="#0e406a" strokeWidth={3} dot={false} />}
          {choice === "Historical" && <Line type="monotone" name="ATC BC Forecast" dataKey="ATC_capacity_BC" stroke="#237cc1" strokeDasharray="4 1 2" strokeWidth={2} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="ATC BC Forecast" dataKey="historical_ATC_capacity_BC" stroke="#237cc1" strokeDasharray="4 1 2" strokeWidth={2} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="ATC BC Actual" dataKey="ATC_capacity_BC_actual" stroke="#0e406a" strokeWidth={3} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="ATC BC Forecast" dataKey="forecast_ATC_capacity_BC" stroke="#237cc1" strokeWidth={2} dot={false} />}

          {choice === "RT" && <Line type="monotone" name="ATC BC MATCL Actual" dataKey="historical_ATC_capacity_BCMATL" stroke="#55622b" strokeWidth={3} dot={false} />}
          {choice === "RT" && <Line type="monotone" name="ATC BC MATCL Forecast" dataKey="forecast_ATC_capacity_BCMATL" stroke="#799B3E" strokeWidth={2} dot={false} />}
          {choice === "Historical" && <Line type="monotone" name="ATC BC MATL Actual" dataKey="ATC_capacity_BCMATL_actual" stroke="#55622b" strokeWidth={3} dot={false} />}
          {choice === "Historical" && <Line type="monotone" name="ATC BC MATL Forecast" dataKey="ATC_capacity_BCMATL" stroke="#799B3E" strokeDasharray="4 1 2" strokeWidth={2} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="ATC BC MATL Forecast" dataKey="historical_ATC_capacity_BCMATL" stroke="#799B3E" strokeDasharray="4 1 2" strokeWidth={2} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="ATC BC MATL Actual" dataKey="ATC_capacity_BCMATL_actual" stroke="#55622b" strokeWidth={3} dot={false} />}
          {choice === "Combined" && <Line type="monotone" name="ATC BC MATL Forecast" dataKey="forecast_ATC_capacity_BCMATL" stroke="#799B3E" strokeWidth={2} dot={false} />}

        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AvailableTransferCapacity;