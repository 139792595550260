import { getTcAccessToken } from "../auth/utils";
import http from "./http";

export class FileDownloadUtilities {
    static initiateCsvFileDownload(url: string, filename: string) {
        const headers = new Headers();
        if (getTcAccessToken()) {
            headers.set('authorization', `Bearer ${getTcAccessToken()}`);
            headers.set('Access-Control-Allow-Origin', '*');
        }
        http.get(url)
            .then(r => {
                FileDownloadUtilities.initiateFileDownload(filename, r.data, "text/csv;charset=utf-8;");
            })
    }

    //may need to check if we need to specify utf 8 or something to that effect
    static initiateReportFileDownload(url: string, filename: string,mimetype:string) {
        const headers = new Headers();
        if (getTcAccessToken()) {
            headers.set('authorization', `Bearer ${getTcAccessToken()}`);
            headers.set('Access-Control-Allow-Origin', '*');
        }
        console.log("getting file")
        console.log(url)
        http.get(url)
            .then(r => {
                console.log(r.status)
                FileDownloadUtilities.downloadPreSignedURL(filename, r.data);
            })
    }

    static downloadPreSignedURL(filename: string, fileContent: any): void {
        // the only way (on most browsers) to save a file with a filename is to click on a blob url
        const link = document.createElement('a');
        link.setAttribute('href', fileContent);
        if (link.download !== undefined) {
            link.setAttribute('download', filename);
        }
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    static initiateFileDownload(filename: string, fileContent: any, mimeTypeString: string): void {
        const blob = new Blob([fileContent], { type: mimeTypeString });
        console.log("filecontent is");
        console.log(fileContent.length);
        // the only way (on most browsers) to save a file with a filename is to click on a blob url
        const link = document.createElement('a');
        // modern browsers support download filename
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        if (link.download !== undefined) {
            link.setAttribute('download', filename);
        }
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
